import { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { useLocation } from "react-router-dom";
import { SUBSCRIBER_FILES_LIMIT, UNSUBSCRIBER_FILES_LIMIT } from "./constants";

// defaultTheme
import theme from "./theme";

// routing
import Routes from "./routes";
import MaintenanceModal from "./layouts/MainLayout/Modals/MaintenanceModal";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setViewerKeyword } from "./redux/app/appSlice";

function App() {
  const { subsDaysLeft } = useSelector((state) => state.app);
  const location = useLocation();
  const dispatch = useDispatch();

  // if url contains "search" query param ask to login
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const searchParam = urlParams.get("search");
    if (searchParam) {
      const limit =
        subsDaysLeft !== null && subsDaysLeft >= 0
          ? SUBSCRIBER_FILES_LIMIT
          : UNSUBSCRIBER_FILES_LIMIT;

      dispatch(
        setViewerKeyword({
          id: null,
          name: searchParam,
          params: `?search_term=${searchParam}&limit=${limit}`,
        })
      );
    }
  }, [location]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme()}>
        <CssBaseline />
        {/** Maintenance Modal **/}
        <MaintenanceModal />

        <Routes />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
