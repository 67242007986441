export const plotColors = {
  red: "#ed4319",
  orange: "#f4a347",
  green: "#a3c388",
  blue: "#1a99aa",
  darkBlue: "#103c46",
  greenAlt: "#88ba01",
};

export const mainCardTypes = {
  COMPONENT_WRAPPER_CARD: "COMPONENT_WRAPPER_CARD",
  COMPONENT_WRAPPER: "COMPONENT_WRAPPER",
};

export const MAIN_TABS_ID = {
  A_TAB: "Sample",
  B_TAB: "Subscribe",
  C_TAB: "Premium",
  D_TAB: "Search+",
};

export const AVAILABLE_PLANS = [
  {
    id: 1,
    title: "Free Trial",
    days: 199, // 1
    actual_price: 0,
    discounted_price: 0,
    price_per_day: 0,
    offer: 100,
    extra_days: null,
  },
  {
    id: 2,
    title: "1 Month",
    days: 33,
    actual_price: 99,
    discounted_price: 99,
    price_per_day: 3.0,
    offer: null,
    extra_days: "3 days",
  },
  {
    id: 3,
    title: "3 Months",
    days: 99,
    actual_price: 300,
    discounted_price: 199,
    price_per_day: 2.0,
    offer: 33,
    extra_days: "7 days",
  },
  {
    id: 4,
    title: "6 Months",
    days: 199,
    actual_price: 600,
    discounted_price: 299,
    price_per_day: 1.5,
    offer: 50,
    extra_days: "15 days",
  },
  {
    id: 5,
    title: "12 Months",
    days: 399,
    actual_price: 1200,
    discounted_price: 399,
    price_per_day: 1.0,
    offer: 66,
    extra_days: "1 month",
  },
];

// Fetch keyword file limit for subs and unsubs
export const SUBSCRIBER_FILES_LIMIT = 25; //25
export const UNSUBSCRIBER_FILES_LIMIT = 100; //5
